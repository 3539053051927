header{
    height: 120vh;
    padding-top: 6rem;
    overflow: hidden;

}
.quote_container{

    padding-bottom: 2rem;
    font-weight: bolder;
}
.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* HeaderLink Section */

.header_link{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;


}

/* HeaderSocials Section */
.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0rem;
    bottom: 3rem;
}

.header_socials::after{
    content: ' ';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);


}


.fade-in {
    color:var(--color-bg-variant);
    font-weight:bolder;
    font-size: large;
    transition: opacity 1.5s ease;
}

.fade-out {
    color:var(--color-bg-variant);
    font-weight:bolder;
    font-size: large;
    opacity: 0;
    transition: opacity 1.5s ease;
}
/* Me Section */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 8rem 1.5rem 1.5rem 1.5rem;
}

/* Medium Size Device Section */

@media screen and (max-width: 1024px){

    header{
        /* height: 80vh; */
    }
}

/* Small Size Device Section */

@media screen and (max-width: 600px){
    header{
        /* height: 110vh; */
    }
    .header_socials{
        display: none;
    }
}